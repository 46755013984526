import { PropsWithChildren } from 'react';
import { Head, Link } from "@inertiajs/react";
import { Toaster } from "@/components/ui/toaster";
import Logo from "@/assets/tunegate.png";

export default function GuestLayout({ title, children }: PropsWithChildren & { title: string }) {
    return (
        <>
            <Head title={title} />
            <main className="container relative flex h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
                <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
                    <div className="absolute inset-0 bg-zinc-900" />
                    <Link href="/" className="relative z-20 flex items-center text-lg font-medium">
                        <img
                            width={24}
                            height={24}
                            src={Logo}
                            className="mr-2 h-6 w-6"
                        />
                        Tunegate
                    </Link>
                    <div className="relative z-20 mt-auto">
                        <blockquote className="space-y-2">
                            <p className="text-lg">
                                &ldquo;Take your tuning passion to the ultimate level.
                                Don’t settle for the ordinary—transform raw power into true art,
                                creating legends that push every boundary and redefine what’s possible in the automotive world.&rdquo;
                            </p>
                            <footer className="text-sm">
                                Tunegate Team
                            </footer>
                        </blockquote>
                    </div>
                </div>
                <div className="lg:p-8">
                    {children}
                </div>
            </main>
            <Toaster />
        </>
    );
}
